<template>
  <div :class="['mainBG', isOpenModal ? 'open' : '']">
    <div class="mainModal">
      <div class="title">
        <div class="icon" :class="[modalType]">
          <img
            src="@/assets/images/Warning-1-circle.svg"
            alt=""
            width="24"
            height="24"
            v-if="modalType == 'warning'"
          />
          <img
            src="@/assets/images/Trash.svg"
            alt=""
            width="24"
            height="24"
            v-if="modalType == 'danger'"
          />
          <img
            src="@/assets/images/selfonboard/question-blue.svg"
            alt=""
            width="24"
            height="24"
            v-if="modalType == 'question'"
          />
        </div>
        <div class="titleText" :style="{ 'font-size': fontSize + 'px' }">
          {{ title }}
        </div>
      </div>
      <div v-if="banner" class="banner">
        <img :src="banner" alt="" />
      </div>
      <div class="details" v-html="details"></div>
      <div class="content">
        <slot></slot>
      </div>
      <div class="btnContainer">
        <button
          id="friendgetfriends_fgfmodal_onactionmodalback_button"
          @click="onCancel()"
          class="outlineprimary"
          :class="[modalType]"
          v-if="showbackto"
        >
          {{ backto }}
        </button>
        <button
          id="friendgetfriends_fgfmodal_onactionmodalconfirm_button"
          @click="onConfirm()"
          class="primarybtn"
          :disabled="disabledConfirm"
          :class="[modalType]"
        >
          <span v-if="confirm"> {{ confirm }}</span>
          <span v-else>
            {{ lbl['fgf-confirm'] }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  mixins: [Mixin],
  props: {
    title: {
      type: String,
    },
    banner: {
      type: String,
      default: null,
    },
    details: {
      type: String,
    },
    backto: {
      type: String,
    },
    confirm: {
      type: String,
    },
    modalType: {
      type: String,
    },
    isOpenModal: {
      type: Boolean,
    },
    showbackto: {
      type: Boolean,
      default: true,
    },
    disabledConfirm: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
    },
    onCancel: {
      type: Function,
    },
    fontSize: {
      type: String,
      default: '24',
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.mainBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .mainModal {
    max-width: 530px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    z-index: 9999;
    text-align: left;
    .title {
      display: flex;
      .icon {
        width: 44px;
        height: 44px;
        padding: 10px;
        border-radius: 4px;
        background-color: #fff3e0;
        &.danger {
          background-color: #ffe0e0;
        }
        &.question {
          background-color: #e6f7ff;
        }
      }
      .titleText {
        margin-left: 8px;
      }
    }
    .banner {
      text-align: center;
      margin: 16px auto;

      img {
        width: 100px;
        height: 100px;
      }
    }
    .details {
      margin-top: 10px;
      font-size: 14px;
      .color-red-day {
        color: red;
      }
    }
    .btnContainer {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
      gap: 24px;
    }
  }
}
</style>
