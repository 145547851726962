<template>
  <div
    id="save_flag"
    :style="{
      'background-color': flagColor,
      'border-color': flagColor,
    }"
  >
    Save
  </div>
</template>

<script>
export default {
  name: 'SaveFlag',
  props: {
    flagColor: {
      type: String,
      default: '#ff5252',
    },
  },
}
</script>

<style lang="scss" scoped>
#save_flag {
  width: fit-content;
  text-align: center;
  padding: 2px 4px;
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}
</style>
