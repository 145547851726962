<template>
  <div class="container-fluid">
    <Header :is-hide-menu="isHideMenu" :is-hide-user="isHideMenu" />
    <a-alert
      v-if="isError"
      type="error"
      message="Error"
      :description="errorMessege"
      showIcon
    />
    <div class="package-page-wrapper">
      <SelectPackage
        v-if="!isCorporateExpired"
        :package-data="packageData"
        :is-user-package-standard="
          !crmPackage.PackageId || crmPackage.IsStandardPackage
        "
        :is-non-standard="isNonStandard"
        @selectedPackage="selectedPackage($event)"
      />
      <ComparePackage
        v-if="
          (!crmPackage.PackageId || crmPackage.IsStandardPackage) &&
          !isCorporateExpired
        "
        :compare-data="compareData"
        @goShopping="goShopping($event)"
      />
      <PackageExpire v-if="isCorporateExpired" />
    </div>
    <ReDirectModal
      :isOpenModal="reDirectModal.isOpenModal"
      :modalType="reDirectModal.modalType"
      :title="reDirectModal.title"
      :details="reDirectModal.details"
      :showbackto="reDirectModal.showbackto"
      :backto="reDirectModal.backto"
      :confirm="reDirectModal.confirm"
      :onConfirm="reDirectModal.onConfirm"
      :onCancel="reDirectModal.onCancel"
      :fontSize="'20'"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Account from '@/helper/AccountHelper.js'
import BzbsCart from '@/core/Cart/service/BzbsCart'
import BzbsPackage from '@/core/Package/service/BzbsPackage'
import ComparePackage from '@/module/Account/SelectPackage/components/ComparePackage.vue'
import Header from '@/components/Layout/Header'
import Mixin from '@/mixin/Mixin'
import SelectPackage from '@/module/Account/SelectPackage/components/SelectPackage.vue'
import PackageExpire from '@/module/Account/SelectPackage/components/PackageExpire.vue'
import ReDirectModal from '@/components/Modal/Component/confirmmodal2'

export default {
  name: 'Package',
  mixins: [Mixin],
  components: {
    Header,
    SelectPackage,
    ComparePackage,
    ReDirectModal,
    PackageExpire,
  },
  data() {
    return {
      packageData: [],
      compareData: {},
      isError: false,
      errorMessege: '',
      reDirectModal: {
        title: '',
        details: '',
        modalType: '',
        backto: '',
        confirm: '',
        showbackto: true,
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      businessName: '',
      isHideMenu: true,
      crmPackage: null,
      isNonStandard: false,
      isCorporateExpired: false,
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.query.checking) {
        this.checkRedirect()
      } else if (newRoute.query.shoppingCart) {
        let configSelfOnBoard = false
        try {
          let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
          if (parse) {
            configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
              ? parse.buzzebeesConfigSelfOnBoard
              : false
          } else {
            configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
          }
        } catch (e) {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }

        if (configSelfOnBoard) {
          this.handleLoading(true)
          Account.apiAccountInformation().then(res => {
            this.handleLoading(false)
            if (res.data.RequirePackage) {
              if (res.data.IsExtendExpired) {
                this.$router
                  .push({
                    name: 'Package',
                    query: { checking: true },
                  })
                  .then(() => {
                    this.$router.go()
                  })
              } else {
                if (this.businessName) {
                  if (this.$route.query.changepackage) {
                    this.$router.push({
                      name: 'Dashboard',
                      query: { changepackage: true },
                    })
                  } else {
                    this.$router.push({
                      name: 'Dashboard',
                    })
                  }
                } else {
                  this.$router.push({ name: 'AccountSetting2' })
                }
              }
            } else {
              this.$router.push({ name: 'Dashboard' })
            }
          })
          this.handleLoading(false)
        }
      }
    },
  },
  created() {
    if (Account.getCacheProfileAddress().strBusinessName) {
      this.businessName = Account.getCacheProfileAddress().strBusinessName
    } else if (Account.getCacheUser().BusinessName) {
      this.businessName = Account.getCacheUser().BusinessName
    } else {
      this.businessName = ''
    }

    let configSelfOnBoard = false
    try {
      let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
      if (parse) {
        configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
          ? parse.buzzebeesConfigSelfOnBoard
          : false
      } else {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }
    } catch (e) {
      configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
    }

    if (configSelfOnBoard) {
      if (this.$route.query.shoppingCart) {
        this.handleLoading(true)
        Account.apiAccountInformation().then(res => {
          this.handleLoading(false)
          if (res.data.RequirePackage) {
            if (res.data.IsExtendExpired) {
              this.$router.push({
                name: 'Package',
                query: { checking: true },
              })
            } else {
              if (this.businessName) {
                if (this.$route.query.changepackage) {
                  this.$router.push({
                    name: 'Dashboard',
                    query: { changepackage: true },
                  })
                } else {
                  this.$router.push({
                    name: 'Dashboard',
                  })
                }
              } else {
                this.$router.push({ name: 'AccountSetting2' })
              }
            }
          } else {
            if (this.$route.query.changepackage) {
              this.$router.push({
                name: 'Dashboard',
                query: { changepackage: true },
              })
            } else {
              this.$router.push({
                name: 'Dashboard',
              })
            }
          }
        })
        this.handleLoading(false)
      } else if (this.$route.query.returnPath) {
        Account.apiAccountInformation().then(res => {
          if (res.data.RequirePackage) {
            if (!res.data.IsExtendExpired) {
              if (this.$route.query.changepackage) {
                this.$router.push({
                  name: 'Dashboard',
                  query: { changepackage: true, isWelcomeBack: true },
                })
              } else {
                this.$router.push({
                  name: 'Dashboard',
                  query: { isWelcomeBack: true },
                })
              }
            }
          } else {
            if (this.$route.query.changepackage) {
              this.$router.push({
                name: 'Dashboard',
                query: { changepackage: true, isWelcomeBack: true },
              })
            } else {
              this.$router.push({
                name: 'Dashboard',
                query: { isWelcomeBack: true },
              })
            }
          }
        })
      }
    }

    Account.bzbsAnalyticTracking(
      'select_package_page',
      'package_month',
      'view_package',
      'on view',
    )
    this.googleTagManager(
      'select_package_page',
      'package_month',
      'view_package',
      null,
      'on view',
    )
    this.handleFooter(true)
    this.init()
  },
  methods: {
    init() {
      this.isCorporateExpired = false
      this.crmPackage = Account.getCachePackage()
      if (this.crmPackage) {
        if (this.crmPackage.ExtendRemainingDay) {
          this.isHideMenu = false
        } else {
          this.isHideMenu = true
          if (this.crmPackage.PackageId == 'corporate') {
            this.isCorporateExpired = true
          }
        }
      }

      Promise.all([this.getPackage(), this.getComparePackage()]).then(
        this.checkRedirect(),
      )
    },
    checkRedirect() {
      let configSelfOnBoard = false
      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }

      if (this.$route.query.checking && configSelfOnBoard) {
        Account.apiAccountInformation().then(res => {
          this.handleLoading(false)
          if (res.data.RequirePackage) {
            if (!res.data.IsExtendExpired) {
              setTimeout(() => {
                this.reDirectModal.title =
                  this.lbl['selfonboard-change-package-success']
                this.reDirectModal.details =
                  this.lbl['selfonboard-go-dashboard']
                this.reDirectModal.modalType = 'warning'
                this.reDirectModal.isOpenModal = true
                this.reDirectModal.backto = null
                this.reDirectModal.showbackto = false
                this.reDirectModal.confirm = null
                this.reDirectModal.onConfirm = () => {
                  this.reDirectModal.isOpenModal = false
                  this.$router.push({ name: 'Dashboard' })
                }
                this.reDirectModal.onCancel = () => {
                  this.reDirectModal.isOpenModal = false
                }
              }, 500)
            }
          } else {
            setTimeout(() => {
              this.reDirectModal.title =
                this.lbl['selfonboard-change-package-success']
              this.reDirectModal.details = this.lbl['selfonboard-go-dashboard']
              this.reDirectModal.modalType = 'warning'
              this.reDirectModal.isOpenModal = true
              this.reDirectModal.backto = null
              this.reDirectModal.showbackto = false
              this.reDirectModal.confirm = null
              this.reDirectModal.onConfirm = () => {
                this.reDirectModal.isOpenModal = false
                this.$router.push({ name: 'Dashboard' })
              }
              this.reDirectModal.onCancel = () => {
                this.reDirectModal.isOpenModal = false
              }
            }, 500)
          }
        })
      }
    },
    getPackage() {
      this.isNonStandard = false
      if (!this.crmPackage.PackageId || this.crmPackage.IsStandardPackage) {
        return new Promise(resolve => {
          BzbsPackage.apiGetPackage()
            .then(res => {
              this.packageData = res.data
              console.log('packageData : ', this.packageData)
              resolve(res.data)
            })
            .catch(error => {
              console.log(error)
              this.handleLoading(false)
              this.isError = true
              this.errorMessege = error.response.data.error.message
              resolve(error)
            })
        })
      } else {
        return new Promise(resolve => {
          BzbsPackage.apiGetNonStandardPackage()
            .then(res => {
              const found = res.data.find(
                item => this.crmPackage.PackageId == item.PackageId,
              )
              this.packageData = []
              this.packageData.push(found)
              this.isNonStandard = true
              console.log('packageData : ', this.packageData)
              resolve(res.data)
            })
            .catch(error => {
              console.log(error)
              this.handleLoading(false)
              this.isError = true
              this.errorMessege = error.response.data.error.message
              resolve(error)
            })
        })
      }
    },
    getComparePackage() {
      return new Promise(resolve => {
        BzbsPackage.apiGetComparePackage()
          .then(res => {
            console.log('getComparePackage :: ', res.data)
            this.compareData = res.data.data
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            console.log(error)
            this.handleLoading(false)
            this.isError = true
            this.errorMessege = error.response.data.error.message
            resolve(error)
          })
      })
    },
    selectedPackage(value) {
      this.handleLoading(true)
      if (value.packageObj.IsFreeTrial) {
        this.selectedFreetrail(value.packageObj.PackageId)
      } else {
        value.isMonthly
          ? this.goShopping(value.packageObj.MonthlyCampaignId)
          : this.goShopping(value.packageObj.YearlyCampaignId)
      }
    },
    selectedFreetrail(packageId) {
      return new Promise(resolve => {
        BzbsPackage.apiPostFreePackage(packageId)
          .then(res => {
            Account.apiAccountInformation().then(res => {
              if (this.businessName) {
                this.$router.push({ name: 'Dashboard' })
              } else {
                this.$router.push({ name: 'AccountSetting2' })
              }

              if (res.data.RequirePackage) {
                if (!res.data.IsExtendExpired) {
                  if (this.$route.query.returnPath) {
                    if (this.businessName) {
                      this.$router.push({
                        name: 'Dashboard',
                        query: { isWelcomeBack: true },
                      })
                    } else {
                      this.$router.push({ name: 'AccountSetting2' })
                    }
                  } else {
                    if (this.businessName) {
                      this.$router.push({
                        name: 'Dashboard',
                      })
                    } else {
                      this.$router.push({ name: 'AccountSetting2' })
                    }
                  }
                }
              }
            })
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log(error)
            this.$emit('error', error.response.data.error.message)
            resolve(error)
          })
      })
    },
    goShopping(packageId) {
      this.handleLoading(true)
      var arrData = [{ id: packageId, qty: 1 }]
      return new Promise(resolve => {
        BzbsCart.apiAddtoCart(packageId, arrData)
          .then(res => {
            console.log('apiAddtoCart :: ', res.data)
            this.goShoppingCart()
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            if (error.response) {
              this.$emit('error', error.response.data.error.message)
            }
            resolve(error)
          })
      })
    },
    goShoppingCart() {
      var params = {
        return_url: '/Account/Package?shoppingCart=true',
        success_url: '/Account/Package?shoppingCart=true',
      }
      BzbsCart.goShopingCart(params)
    },
    handleError(e) {
      console.log('error messsege :: ', e)
      this.isError = true
      this.errorMessege = e
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.container-fluid {
  background-size: cover !important;
  background-color: #ffffff !important;
  background-attachment: fixed;
  background-position: center;
  .package-page-wrapper {
    margin-top: 32px;
    text-align: center;
  }
}
</style>
