<template>
  <div class="select-package-wrapper">
    <h1 class="toppic-text">
      {{ lbl['package-select-package-title'] }}
    </h1>
    <div v-if="isUserPackageStandard" class="select-package-pay-type">
      <a-button
        @click="clickMonthly"
        class="select-package-pay-type-button"
        :class="[isMonthly ? 'active' : '']"
      >
        {{ lbl['package-select-package-monthly-button'] }}
      </a-button>
      <a-button
        @click="clickYearly"
        class="select-package-pay-type-button"
        :class="[!isMonthly ? 'active' : '']"
      >
        {{ lbl['package-select-package-yearly-button'] }}
        <SaveFlag flag-color="#ff5252" />
      </a-button>
    </div>
    <div class="package-card-container">
      <div
        class="card"
        v-for="packageItem in packageData"
        :key="packageItem.PackageId"
      >
        <section class="header">
          <div class="package-name-container">
            <span class="package-name">
              {{ packageItem.PackageName }}
            </span>
            <div v-if="packageItem.IsRecommended" class="package-recommended">
              <span>
                {{ lbl['package-select-package-recommended-text'] }}
              </span>
              <img src="@/assets/images/shield.svg" alt="" />
            </div>
          </div>
          <div class="package-price-container">
            <span
              v-if="isMonthly"
              class="package-price"
              :class="{
                free: packageItem.MonthlyPrice == 0,
                promotion: packageItem.MonthlyOriginalPrice !== 0,
              }"
            >
              {{
                packageItem.MonthlyPrice == 0
                  ? 'Free'
                  : formatNumber(packageItem.MonthlyPrice)
              }}
            </span>
            <span
              v-else
              class="package-price"
              :class="{
                free: packageItem.YearlyPrice == 0,
                promotion: packageItem.YearlyOriginalPrice !== 0,
              }"
            >
              {{
                packageItem.YearlyPrice == 0
                  ? 'Free'
                  : formatNumber(packageItem.YearlyPrice)
              }}
            </span>
            <span
              v-if="
                !isNonStandard &&
                isMonthly &&
                packageItem.MonthlyOriginalPrice !== 0
              "
              class="original-package-price"
            >
              {{ formatNumber(packageItem.MonthlyOriginalPrice) }}
            </span>
            <span
              v-if="!isMonthly && packageItem.YearlyOriginalPrice !== 0"
              class="original-package-price"
            >
              {{ formatNumber(packageItem.YearlyOriginalPrice) }}
            </span>
            <span v-if="packageItem.isMonthly !== 0" class="package-price-unit">
              {{ displayUnit(packageItem) }}
            </span>
          </div>
        </section>
        <div class="line" />
        <section class="feature">
          <span class="title">
            {{ lbl['package-select-package-feature-title'] }}
          </span>
          <div class="feature-detail">
            <ul>
              <li v-for="item in packageItem.Features" :key="item.FratureId">
                <img
                  :src="require(`@/assets/images/${item.IconUrl}.svg`)"
                  alt=""
                />
                {{ item.FeatureName }}
              </li>
            </ul>
          </div>
        </section>
        <div class="line" />
        <section class="select-package">
          <button
            :id="`select-package-${packageItem.PackageId}-button`"
            class="select-package-button"
            @click="selectedPackage(packageItem)"
          >
            <span class="title">
              {{ lbl['package-select-package-button'] }}
            </span>
            <div class="price">
              <span v-if="isMonthly" class="package-price">
                {{
                  packageItem.MonthlyPrice == 0
                    ? displayFreeDetail(packageItem)
                    : formatNumber(packageItem.MonthlyPrice)
                }}
              </span>
              <span v-else class="package-price">
                {{
                  packageItem.YearlyPrice == 0
                    ? displayFreeDetail(packageItem)
                    : formatNumber(packageItem.YearlyPrice)
                }}
              </span>
              <span v-if="!packageItem.IsFreeTrial" class="package-price-unit">
                {{
                  isMonthly
                    ? lbl['package-select-package-monetary-unit-per-month-text']
                    : lbl['package-select-package-monetary-unit-per-year-text']
                }}
              </span>
            </div>
          </button>
          <p v-if="!isNonStandard" class="price-detail">
            <span>
              {{
                packageItem.MonthlyPrice == 0
                  ? displayFreeDetail(packageItem)
                  : formatNumber(packageItem.MonthlyPrice) +
                    ' ' +
                    lbl['package-select-package-monetary-unit-per-month-text'] +
                    lbl['package-select-package-or-text'] +
                    formatNumber(packageItem.YearlyPrice) +
                    ' ' +
                    lbl['package-select-package-monetary-unit-per-year-text']
              }}
            </span>
          </p>
        </section>
      </div>
    </div>
    <div class="noted">
      <span>{{ lbl['package-select-package-noted'] }}</span>
    </div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import SaveFlag from '@/module/Account/SelectPackage/components/SaveFlag'

export default {
  name: 'SelectPackage',
  mixins: [Mixin],
  components: {
    SaveFlag,
  },
  props: {
    packageData: Array,
    isUserPackageStandard: Boolean,
    isNonStandard: Boolean,
  },
  data() {
    return {
      isMonthly: true,
    }
  },
  methods: {
    displayUnit(item) {
      if (item.IsFreeTrial) {
        return (
          this.lbl['package-select-package-monetary-unit-per-text'] +
          this.lbl['package-select-package-day-text'].replace(
            '{0}',
            item.MonthlyUsageDay,
          )
        )
      } else {
        if (this.isMonthly)
          return this.lbl['package-select-package-monetary-unit-per-month-text']
        else
          return this.lbl['package-select-package-monetary-unit-per-year-text']
      }
    },
    displayFreeDetail(item) {
      if (item.IsFreeTrial) {
        console.log(this.lbl['package-select-package-free-text'])
        console.log(
          this.lbl['package-select-package-free-text'].replace(
            '30',
            item.MonthlyUsageDay,
          ),
        )

        if (item.MonthlyUsageDay) {
          return this.lbl['package-select-package-free-text'].replace(
            '{0}',
            item.MonthlyUsageDay,
          )
        } else {
          return this.lbl['package-select-package-free-text'].replace(
            '{0}',
            '30',
          )
        }
      } else {
        return this.lbl['package-select-package-free-text'].replace('{0}', '30')
      }
    },
    formatNumber(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    clickMonthly() {
      this.isMonthly = true
      Account.bzbsAnalyticTracking(
        'select_package_page',
        'package_month',
        'click_monthly',
        'on click',
      )
      this.googleTagManager(
        'select_package_page',
        'package_month',
        'click_monthly',
        null,
        'on click',
      )
    },
    clickYearly() {
      this.isMonthly = false
      Account.bzbsAnalyticTracking(
        'select_package_page',
        'package_year',
        'click_yearly',
        'on click',
      )
      this.googleTagManager(
        'select_package_page',
        'package_year',
        'click_yearly',
        null,
        'on click',
      )
    },
    selectedPackage(packageObj) {
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'select_package_page',
        'package_month',
        'click_' + packageObj.PackageId,
        'on click',
      )
      this.googleTagManager(
        'select_package_page',
        'package_' + this.isMonthly ? 'month' : 'year',
        'click_' + packageObj.PackageId,
        null,
        'on click',
      )
      this.$emit('selectedPackage', {
        packageObj: packageObj,
        isMonthly: this.isMonthly,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.select-package-wrapper {
  align-content: center;
  text-align: -webkit-center;
  margin-bottom: 84px;
  .toppic-text {
    color: $body;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 32px;
  }
  .select-package-pay-type {
    width: 40%;
    min-width: 150px;
    border: 1px solid #efefef;
    background-color: #eeeeee;
    border-radius: 6px;
    padding: 4px;
    margin: 0 auto 48px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;

    .select-package-pay-type-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border: 0;
      background-color: transparent;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
      color: $text;
      padding: 4px 32px;
      flex-grow: 1;
    }
    .active {
      background-color: #ffffff;
      border: 1px solid $primary;
    }
  }

  .package-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    .card {
      flex: 1;
      max-width: 370px;
      border: 1px solid $color-grey-e0;
      text-align: left;
      padding: 24px;

      .header {
        font-size: 20px;
        font-weight: 500;

        .package-name-container {
          display: flex;
          flex-wrap: wrap;
          column-gap: 12px;
          justify-content: space-between;
          align-items: center;
          .package-name {
            color: $color-grey-61;
          }
          .package-recommended {
            font-size: 14px;
            color: $text-light-gray;
            display: flex;
            align-items: center;
            column-gap: 8px;
            align-self: center;
          }
        }
        .package-price-container {
          .package-price {
            color: $text-dark;
            font-size: 34px;
            font-weight: 500;
          }
          .original-package-price {
            color: $text-light-gray;
            font-size: 24px;
            text-decoration: line-through;
            margin-right: 8px;
          }
          .package-price-unit {
            font-size: 14px;
            color: $text-light-gray;
          }
          .promotion {
            color: $color-red-52;
          }
          .free {
            color: $color-green-1;
          }
        }
      }
      .feature {
        color: $color-grey-75;
        font-size: 16px;
        .title {
          font-weight: 500;
        }
        .feature-detail {
          margin-top: 10px;
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .select-package {
        text-align: center;
        .select-package-button {
          width: 100%;
          color: $primary;
          border: 1px solid $primary;
          border-radius: 6px;
          padding: 16px;
          background-color: #ffffff;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          .title {
            font-weight: bold;
          }
          .price {
            font-size: 12px;
          }

          &:hover,
          &:active {
            color: #ffffff;
            background-color: $primary;
          }
        }

        .price-detail {
          font-size: 14px;
          color: $text-light-gray;
          margin: 8px 0 0;
        }
      }
    }
    .card:hover {
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
      margin-top: -22px;
      margin-bottom: 22px;
    }
  }

  .noted {
    margin: 26px 20px 0 20px;
    text-align: center;
    color: $btn-danger;
  }

  .line {
    height: 1px;
    background-color: $color-grey-e0;
    margin: 16px -24px;
  }
}
</style>
