<template>
  <div class="compare-package-wrapper">
    <h1 class="toppic-text">
      {{ lbl['package-compare-package-title'] }}
    </h1>
    <section class="compare-table-container">
      <div
        v-if="compareData.PackageFeatures"
        class="compare-container"
        :style="`grid-template-columns: repeat(${
          compareData.PackageFeatures.length + 1
        }, 1fr)`"
      >
        <div class="features-topic">
          {{ lbl['package-select-package-feature-title'] }}
        </div>
        <section
          class="package-topic"
          v-for="topic in compareData.PackageFeatures"
          :key="`topic-${topic.PackageId}`"
        >
          <div class="package-topic-text">
            <div class="package-name-container">
              <span class="package-name">
                {{ topic.PackageName }}
              </span>
              <div v-if="topic.IsRecommended" class="package-recommended">
                <span>
                  {{ lbl['package-select-package-recommended-text'] }}
                </span>
                <img src="@/assets/images/shield.svg" alt="" />
              </div>
            </div>
            <div class="package-price-container">
              <span class="package-price">
                {{ formatNumber(topic.MonthlyPrice) }}
              </span>
              <span class="package-price-unit">
                {{ lbl['package-select-package-monetary-unit-per-month-text'] }}
              </span>
            </div>
          </div>
          <button
            :id="`top-select-package-${topic.PackageId}-button`"
            class="select-package"
            @click="goShopping(topic.MonthlyCampaignId), 'top'"
          >
            {{ lbl['package-select-package-button'] }}
          </button>
        </section>
        <section
          class="features-sub-topic"
          v-for="(featureGroupItem, index) in compareData.MasterFeatures"
          :key="index"
        >
          <div :id="featureGroupItem.FeatureGroupId" class="feature-group-name">
            {{ featureGroupItem.FeatureGroupName }}
          </div>
          <div
            class="feature-detail"
            :style="`grid-template-columns: repeat(${
              compareData.PackageFeatures.length + 1
            }, 1fr)`"
            v-for="(featureItem, index) in featureGroupItem.Features"
            :key="index"
          >
            <div :id="featureItem.FeatureId" class="feature">
              {{ featureItem.FeatureName }}
            </div>
            <div
              class="feature-compare"
              v-for="(item, index) in compareData.PackageFeatures"
              :key="index"
            >
              <img
                v-if="
                  mapFeature(featureItem.FeatureId, item.Features) == 'checked'
                "
                :src="
                  require(`@/assets/images/${mapFeature(
                    featureItem.FeatureId,
                    item.Features,
                  )}.svg`)
                "
                alt=""
              />
              <div v-else>
                {{ mapFeature(featureItem.FeatureId, item.Features) }}
              </div>
            </div>
          </div>
        </section>
        <div class="empty-box" />
        <section
          class="selected-package-section"
          v-for="(packageItem, index) in compareData.PackageFeatures"
          :key="`select-package-${packageItem.PackageId}-section`"
          :id="`select-package-${packageItem.PackageId}-section`"
        >
          <div class="wrapper">
            <button
              :id="`bottom-select-package-${packageItem.PackageId}-per-month-button`"
              class="select-package-button"
              @click="goShopping(packageItem.MonthlyCampaignId, 'end')"
            >
              <span class="title">
                {{ lbl['package-select-package-button'] }}
              </span>
              <div class="price">
                <span class="package-price">
                  {{ formatNumber(packageItem.MonthlyPrice) }}
                </span>
                <span class="package-price-unit">
                  {{
                    lbl['package-select-package-monetary-unit-per-month-text']
                  }}
                </span>
              </div>
            </button>
            <p class="price-detail">
              <span>
                {{
                  formatNumber(packageItem.MonthlyPrice) +
                  ' ' +
                  lbl['package-select-package-monetary-unit-per-month-text'] +
                  lbl['package-select-package-or-text'] +
                  formatNumber(packageItem.YearlyPrice) +
                  ' ' +
                  lbl['package-select-package-monetary-unit-per-year-text']
                }}
              </span>
            </p>
            <div class="line" />
            <span class="annual-payment-text">
              {{ lbl['package-compare-package-annual-payment-text'] }}
              <SaveFlag
                v-if="
                  index == Object.keys(compareData.PackageFeatures).length - 1
                "
                flag-color="#ff5252"
              />
            </span>
            <button
              :id="`bottom-select-package-${packageItem.PackageId}-per-year-button`"
              class="select-package-per-year-button"
              @click="goShopping(packageItem.YearlyCampaignId, 'yearly')"
            >
              {{
                lbl['package-compare-package-pay-text'] +
                ' ' +
                formatNumber(packageItem.YearlyPrice) +
                ' ' +
                lbl['package-select-package-monetary-unit-per-year-text']
              }}
            </button>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import SaveFlag from '@/module/Account/SelectPackage/components/SaveFlag'

export default {
  name: 'ComparePackage',
  mixins: [Mixin],
  components: {
    SaveFlag,
  },
  props: {
    compareData: Object,
  },
  data() {
    return {}
  },
  methods: {
    formatNumber(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    mapFeature(featureId, featureArr) {
      let mappingFeature = featureArr.find(
        feature => feature.FeatureId === featureId,
      )
      if (mappingFeature == undefined) {
        return '-'
      } else {
        if (mappingFeature.FeatureDetail == null) {
          return 'checked'
        } else {
          return mappingFeature.FeatureDetail
        }
      }
    },
    goShopping(packageId, position) {
      console.log('packageId :: ', packageId, position)
      Account.bzbsAnalyticTracking(
        'select_package_page',
        'package_month',
        'click_compare_feature_' + packageId + '_select' + position,
        'on click',
      )
      this.googleTagManager(
        'select_package_page',
        'package_' + position == 'yearly' ? 'year' : 'month',
        'click_compare_feature_' + packageId + '_select' + position,
        null,
        'on click',
      )
      this.$emit('goShopping', packageId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.compare-package-wrapper {
  background-color: #ffffff;
  margin: auto -15px;
  align-content: center;
  text-align: -webkit-center;
  .toppic-text {
    color: $body;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 32px;
  }
  .compare-table-container {
    margin: auto 15px;
    width: 70%;

    .compare-container {
      display: grid;
      border: solid 1px $border;
      font-size: 14px;
      color: $color-grey-61;

      .features-topic {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        align-self: end;
        color: $text-light-gray;
        padding: 24px 32px;
        border-right: solid 1px $border;
      }
      .package-topic {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 24px 32px;
        text-align: left;
        border-right: solid 1px $border;

        .package-topic-text {
          .package-name-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 12px;
            justify-content: space-between;
            align-items: center;
            .package-name {
              font-size: 20px;
              font-weight: 500;
              color: $color-grey-61;
            }
            .package-recommended {
              width: fit-content;
              border-radius: 2px;
              background-color: $color-grey-fa;
              color: $text-light-gray;
              font-size: 14px;
              font-weight: 500;
              padding: 4px;
            }
          }
          .package-price-container {
            font-weight: 500;

            .package-price {
              font-size: 34px;
              text-align: left;
              color: $text-dark;
            }
            .package-price-unit {
              font-size: 14px;
              color: $text-light-gray;
            }
          }
        }
        .select-package {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          background-color: $orange;
          border-radius: 6px;
          padding: 8px 16px;
          margin-top: 16px;
          border: 0;
        }
      }
      .features-sub-topic {
        border-right: solid 1px $border;
        text-align: left;
        grid-column-start: 1;
        grid-column-end: 4;

        .feature-group-name {
          grid-column-start: 1;
          grid-column-end: 3;
          padding: 8px 32px;
          font-weight: 500;
          background-color: $color-grey-fa;
        }
        .feature-detail {
          display: grid;
          border-bottom: solid 1px $border;
          background-color: #ffffff;

          .feature {
            padding: 8px 32px;
          }
          .feature-compare {
            border-left: 1px solid $border;
            padding: 8px 32px;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            align-self: center;
          }
        }
      }
      .selected-package-section {
        border: solid 1px $border;
        .wrapper {
          margin: 16px 32px;
          .select-package-button {
            width: 100%;
            color: #fff;
            padding: 16px 24px;
            border-radius: 6px;
            border: 0;
            background-color: $orange;
            margin-bottom: 8px;

            .title {
              font-size: 16px;
              font-weight: bold;
            }
            .price {
              font-size: 12px;
              margin-top: 4px;
            }
          }
          .price-detail {
            font-size: 14px;
            font-weight: 500;
            padding: 0;
            margin: 0;
            color: $text-light-gray;
          }
          .annual-payment-text {
            font-weight: 500;
            color: $text-dark-gray;
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;
          }
          .select-package-per-year-button {
            width: 100%;
            margin-top: 16px;
            padding: 8px;
            font-size: 16px;
            font-weight: bold;
            color: $orange;
            background-color: #ffffff;
            border: 1px solid $orange;
            border-radius: 6px;
          }
        }
      }
    }
  }
  .line {
    height: 1px;
    background-color: $border;
    margin: 16px 0;
  }
}
</style>
