<template>
  <div class="package-expired-wrapper">
    <h1>{{ lbl['package-expired-title'] }}</h1>
    <span>{{ lbl['package-expired-desc'] }}</span>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'

export default {
  name: 'PackageExpire',
  mixins: [Mixin],
}
</script>

<style lang="scss" scoped>
.package-expired-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>